import { Grid, MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import { useFormikContext } from 'formik'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import { FieldsProps } from '.'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import { displayAsCurrency } from '../../../../utils/displayAsCurrency'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { AdgroupExtendedSettingsFormInterface } from '../Settings'
import { DailyBudgetType } from '../../../../__generated__/graphql'

const DailyBudget: React.FC<FieldsProps> = ({ adgroup, isWizard = false, canEdit = true }): JSX.Element => {
  const t = useAppTranslations()
  const {
    values: { dailyBudgetType },
  } = useFormikContext<AdgroupExtendedSettingsFormInterface>()

  const dailyBudgetTypesOptions = useMemo(
    () =>
      mapEnumForSelect(DailyBudgetType).map((type, i) => (
        <MenuItem value={type.value} key={i}>
          {t(`adgroup.budgetAndDates.budgetType.${type.label}`)}
        </MenuItem>
      )),
    [t]
  )

  return (
    <Grid container item xs={3} spacing={1}>
      <Grid xs={5} item>
        <TransformDisplayToEdition
          type="number"
          inputType="select"
          withNone={false}
          name="dailyBudgetType"
          required={true}
          label="adgroup.budgetAndDates.budgetTypeLabel"
          text={t(dailyBudgetType)}
          tooltipText="adgroup.budgetAndDates.dailyBudgetHelper"
          disableHoverEdition={isWizard}
          editable={canEdit}
        >
          {dailyBudgetTypesOptions}
        </TransformDisplayToEdition>
      </Grid>
      <Grid item xs={5}>
        {dailyBudgetType === DailyBudgetType.Fixed && (
          <TransformDisplayToEdition
            type="number"
            name="dailyBudget"
            required={true}
            label="adgroup.details.dailyBudgetWithCurrency"
            text={displayAsCurrency(adgroup.dailyBudget)}
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: { min: 15 },
            }}
            disableHoverEdition={isWizard}
            editable={canEdit}
          />
        )}
      </Grid>
    </Grid>
  )
}
export default DailyBudget
