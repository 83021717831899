import { Grid, MenuItem } from '@mui/material'
import React, { useMemo } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import { displayAsCurrency } from '../../../../utils/displayAsCurrency'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import { AdGroupCreateInput, AdGroupInput, BudgetCapping as BudgetCappingEnum } from '../../../../__generated__/graphql'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { FieldsProps } from '.'
import { AdgroupExtendedSettingsFormInterface } from '../Settings'
import { useFormikContext } from 'formik'
export interface BudgetCappingProps {
  adgroup: AdGroupInput | AdGroupCreateInput
}
const BudgetCapping: React.FC<BudgetCappingProps & Pick<FieldsProps, 'isWizard' | 'canEdit'>> = ({
  adgroup,
  isWizard = false,
  canEdit = true,
}): JSX.Element => {
  const t = useAppTranslations()
  const {
    values: { budgetCappingType },
  } = useFormikContext<AdgroupExtendedSettingsFormInterface>()

  const dailyBudgetTypesOptions = useMemo(
    () =>
      mapEnumForSelect(BudgetCappingEnum).map((type, i) => (
        <MenuItem value={type.value} key={i}>
          {type.label}
        </MenuItem>
      )),
    [t]
  )
  return (
    <Grid container item xs={3} spacing={1}>
      <Grid xs={5} item>
        <TransformDisplayToEdition
          type="number"
          inputType="select"
          withNone={false}
          name="budgetCappingType"
          required={true}
          label={t('adgroup.budgetAndDates.budgetCappingPeriod')}
          text={budgetCappingType}
          disableHoverEdition={isWizard}
          editable={canEdit}
        >
          {dailyBudgetTypesOptions}
        </TransformDisplayToEdition>
      </Grid>
      {budgetCappingType !== BudgetCappingEnum.Open && (
        <Grid xs={5} item>
          <TransformDisplayToEdition
            type="number"
            name="budgetCapping"
            label={t('adgroup.budgetAndDates.budgetCapping')}
            text={displayAsCurrency(adgroup.budgetCapping)}
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            tooltipText="adgroup.budgetAndDates.budgetCappingHelper"
            disableHoverEdition={isWizard}
            editable={canEdit}
          />
        </Grid>
      )}
    </Grid>
  )
}
export default BudgetCapping
